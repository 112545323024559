import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

// services
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { removeHTML } from "../../utils/util";
import { getServices } from "../../services/services.services";
import { getCMSPageTitle } from "../../services/home/home.services";
import getFileUrl from "../../utils/getFileUrl";

const DEFAULT_PAGE_SIZE = 4;
const service_slug = "services-page";

export const Services = () => {
  const navigate = useNavigate();

  const navigateToServiceDetail = (slug) => {
    navigate(`/services/${slug}`);
  };

  // Queries
  const { data: services, isPending: servicesLoading } = useQuery({
    queryKey: ["services"],
    queryFn: () => getServices(10),
  });
  const { data: pageTitle, isPending: pageTitleLoading } = useQuery({
    queryKey: ["pagetitle-cms", service_slug],
    queryFn: () => getCMSPageTitle(service_slug, DEFAULT_PAGE_SIZE),
  });

  if (servicesLoading || pageTitleLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{`Epark Consultancy | ${pageTitle?.title}`} </title>
      </Helmet>
      {/* Services Section */}
      <section className="div-wrapper tw-mb-[7.3rem]">
        <div className="features tw-mt-[1rem] lg:tw-mt-[5.6rem]" id="features">
          <div className="container features-wrapper max-container padding-x">
            <div className="content-header">
              <div className="content d-flex flex-column align-items-center">
                <span className="top-title">All Services</span>

                <h3 className="title tw-w-full">
                  {/* {removeHTML(pageTitle?.description)} */}
                  We provide a variety of services
                </h3>
              </div>
            </div>

            <div className="content-main">
              <div className="tw-flex tw-flex-wrap tw-gap-4 tw-justify-center">
                {services?.map((service) => {
                  const { id, title, description, slug, image } = service;
                  return (
                    <div
                      id={id}
                      className="tw-flex tw-flex-col tw-w-full md:tw-w-[42%] lg:tw-w-[27%] tw-cursor-pointer tw-shadow-[0_3px_10px_rgb(0,0,0,0.2)] tw-p-4 hover:tw-scale-105 tw-transition-transform"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigateToServiceDetail(slug);
                      }}
                    >
                      <div className="tw-flex tw-w-full tw-h-[200px]">
                        <img
                          src={getFileUrl(image)}
                          className="tw-object-cover tw-w-full tw-h-full tw-rounded-md"
                          alt="company"
                        />
                      </div>
                      <h3 className="tw-text-xl tw-font-medium tw-mt-5 tw-capitalize">
                        {/* {category} :{item.title} */}
                        {title}
                      </h3>
                      {/* <p className="tw-text-base tw-text-[#757575] tw-pt-3 tw-pb-5">
                        {removeHTML(shortDescription)}
                      </p> */}
  
                      {/* social icon buttons */}
                      {/* <PortfolioIcons item={item} /> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
