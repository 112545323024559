// import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { removeHTML } from "../../../utils/util";
import LoadingSpinner from "../../../components/spinner/LoadingSpinner";

// services
import { getServices } from "../../../services/services.services";
import { getCMS } from "../../../services/home/home.services";
import getFileUrl from "../../../utils/getFileUrl";

const DEFAULT_PAGE_SIZE = 4;
const service_slug = "services-page";

function Features() {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToServiceDetail = (slug) => {
    navigate(`/services/${slug}`);
  };

  const navigateToServices = () => {
    navigate("/services");
  };

  // Queries
  const { data: services, isPending: servicesLoading } = useQuery({
    queryKey: ["services"],
    queryFn: () => getServices(6),
  });
  const { data: serviceCMS, isPending: serviceCMSLoading } = useQuery({
    queryKey: ["service-cms", service_slug],
    queryFn: () => getCMS(service_slug, DEFAULT_PAGE_SIZE),
  });

  if (servicesLoading || serviceCMSLoading) {
    return <LoadingSpinner />;
  }

  // Check if the current route matches '/'
  const isHomeRoute = location.pathname === "/";

  return (
    <>
      <div className="features" id="features">
        <div className="container features-wrapper max-container padding-x">
          <div className="content-header">
            <div className="content d-flex flex-column align-items-center">
              {isHomeRoute ? (
                <span className="top-title">{serviceCMS?.[0]?.title}</span>
              ) : (
                <span className="top-title">What We Provide</span>
              )}
              {isHomeRoute ? (
                <h3 className="title tw-w-full md:tw-w-3/5">
                  {removeHTML(serviceCMS?.[0]?.description)}
                </h3>
              ) : (
                <h3 className="title">Our Other Services</h3>
              )}
            </div>
          </div>

          <div className="content-main">
          <div className="tw-flex tw-flex-wrap tw-gap-5 tw-justify-center">
              {services?.map((service) => {
                const { id, title,shortDescription, description, slug, image } = service;
                return (
                  <div
                    id={id}
                    className="tw-flex tw-flex-col tw-w-full md:tw-w-[42%] lg:tw-w-[27%] tw-cursor-pointer tw-shadow-[0_3px_10px_rgb(0,0,0,0.2)] tw-p-4 hover:tw-scale-105 tw-transition-transform"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigateToServiceDetail(slug);
                    }}
                  >
                    <div className="tw-flex tw-w-full tw-h-[200px]">
                      <img
                        src={getFileUrl(image)}
                        className="tw-object-cover tw-w-full tw-h-full tw-rounded-md"
                        alt="company"
                      />
                    </div>
                    <h3 className="tw-text-xl tw-font-medium tw-mt-5 tw-capitalize">
                      {/* {category} :{item.title} */}
                      {title}
                    </h3>
                    {/* <p className="tw-text-base tw-text-[#757575] tw-pt-3 tw-pb-5">
                      {removeHTML(shortDescription)}
                    </p> */}

                    {/* social icon buttons */}
                    {/* <PortfolioIcons item={item} /> */}
                  </div>
                );
              })}
            </div>
          </div>

          {isHomeRoute && (
            <div className="tw-flex tw-justify-center">
              <button
                className="btn-orange tw-mt-10"
                onClick={navigateToServices}
              >
                View All Services
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Features;
