import { useState } from "react";
import ReactQuill from "react-quill";

function TrainingAccordion({ item }) {
  const [showDetail, setShowDetail] = useState(true);

  return (
    <div className="accordion-item">
      <details>
        <summary
          className={`accordion-button ${showDetail && "collapsed"}`}
          onClick={() => setShowDetail(!showDetail)}
        >
          {item?.title}
        </summary>
        <div className="accordion-collapse">
          <div className="accordion-body">
            <div>
              <ReactQuill
                value={item?.description}
                readOnly={true}
                theme={"bubble"}
              />
            </div>
          </div>
        </div>
      </details>
    </div>
  );
}

export default TrainingAccordion;
