import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";
import "./blog-detail.css";
import ReactQuill from "react-quill";

function BlogDetail() {
  const { id } = useParams();
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      const res = await makeRequest(`/blogs/${id}`);
      if (res) {
        setBlog(res.data?.data);
      }
    };

    fetchBlog();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={removeHTML(blog.description)} />
      </Helmet>
      <main>
        <section className="top-banner">
          <div className="container-fluid px-0 top-banner-wrapper">
            <section className="blog-detail" id="blog-detail">
              <div className="container">
                <div className="content-main">
                  <img
                    src={blog.image ? getFileUrl(blog.image) : ""}
                    className="home-banner col-12"
                    alt=""
                  />
                  <div className="content-bottom">
                    <div className="content-description">
                      <h1>{blog.title}</h1>
                      <p>
                        <ReactQuill
                          value={blog.description}
                          readOnly={true}
                          theme={"bubble"}
                        />
                      </p>
                    </div>
                    {/* <div className="share">
                      <i className="fa-solid fa-share-nodes" />
                      <span>Share</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </>
  );
}

export default BlogDetail;
