import { BrowserRouter } from "react-router-dom";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import PopUp from "./components/pop-up/pop-up";
import ScrollToTop from "./components/scrollRestoration/scrollRestoration";
import PageRoutes from "./pages/pageRoutes";
import "react-quill/dist/quill.snow.css";
import "./css/quill.snow.css";
import "./css/style.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <PopUp />
        <ScrollToTop />
        <Header></Header>
        <PageRoutes></PageRoutes>
        <Footer></Footer>
      </BrowserRouter>
    </>
  );
}

export default App;
