import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";

function HeadlineBlog() {
  const [blogs, setBlogs] = useState([]);
  const [headlineBlog, setHeadlineBlog] = useState([]);

  const fetchHeadline = async () => {
    const res = await makeRequest(`/blogs`);
    if (res) {
      const data = res.data?.data;
      const reverseArray = data.reverse();
      setHeadlineBlog(reverseArray[0]);
      setBlogs(reverseArray.splice(0, 4));
    }
  };

  useEffect(() => {
    fetchHeadline();
  }, []);

  return (
    <>
      <section className="home" id="home">
        <div className="max-container home-wrapper padding-x">
          {blogs.length > 0 && (
            <>
              <div className="content-left col-lg-9 col-md-12">
                <Link
                  className="card text-white"
                  to={`/blogs/${headlineBlog.slug}`}
                >
                  <img
                    src={
                      blogs[0].image
                        ? getFileUrl(headlineBlog.image)
                        : "assets/images/blog1.png"
                    }
                    className="card-img"
                    alt="..."
                  />
                  <div className="card-img-overlay">
                    <p className="card-text">{headlineBlog.title}</p>
                  </div>
                </Link>
              </div>

              <div className="content-right col-lg-3">
                {blogs
                  .filter((blog) => blog !== blogs[0])
                  .map((blog, id) => {
                    return (
                      <Link
                        key={id}
                        className="card text-white"
                        to={`/blogs/${blog.slug}`}
                      >
                        <img
                          src={
                            blog.image
                              ? getFileUrl(blog.image)
                              : "assets/images/blog1.png"
                          }
                          className="card-img"
                          alt="..."
                        />
                        <div className="card-img-overlay">
                          <p className="card-text">{blog.title}</p>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default HeadlineBlog;
