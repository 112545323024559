import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

import "./about.css";

// import slick slider styles
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";

import { AiOutlineRight } from "react-icons/ai";
import { GiMeditation } from "react-icons/gi";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";

//services
import { getCMS, getCMSPageTitle } from "../../services/home/home.services";
import { getGallery, getTeam } from "../../services/about/about.services";

const about_slug = "about-page";
const DEFAULT_PAGE_SIZE = 4;

const parseHTML = (htmlString) => {
  return { __html: htmlString };
};

function NextBtn(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <AiOutlineRight size={24} color="#818181" />
    </div>
  );
}

function About() {
  const navigate = useNavigate();

  const navigateToCareer = () => {
    window.scrollTo(0, 0);
    navigate("/careers");
  };

  // Queries
  const { data: aboutCMS, isPending: aboutCMSLoading } = useQuery({
    queryKey: ["about-cms", about_slug],
    queryFn: () => getCMS(about_slug, DEFAULT_PAGE_SIZE),
  });
  const { data: pageTitle, isPending: pageTitleLoading } = useQuery({
    queryKey: ["pagetitle-cms", about_slug],
    queryFn: () => getCMSPageTitle(about_slug, DEFAULT_PAGE_SIZE),
  });
  const { data: team, isPending: teamLoading } = useQuery({
    queryKey: ["team"],
    queryFn: () => getTeam(DEFAULT_PAGE_SIZE),
  });
  const { data: gallery, isPending: galleryLoading } = useQuery({
    queryKey: ["gallery"],
    queryFn: () => getGallery(6),
  });

  // About page cms slicing
  const benefits = aboutCMS?.slice(11, 17);

  if (aboutCMSLoading || pageTitleLoading || teamLoading || galleryLoading) {
    return <LoadingSpinner />;
  }

  const teamFirst = gallery
    .map((item) => item.image)
    .flat()
    .slice(0, 3);
  const teamSecond = gallery
    .map((item) => item.image)
    .flat()
    .slice(3, 8);

  const settings = {
    nextArrow: <NextBtn />,
    dots: false,
    infinite: true,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    variableWidth: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const backgroundImageUrl = window.innerWidth <= 768 ? pageTitle.image : aboutCMS?.[0]?.image;
  return (
    <>
      <Helmet>
        <title>{`Epark Consultancy | ${pageTitle?.title}`}</title>
        <meta name="description" content={aboutCMS?.metaDescription} />
      </Helmet>

      <main>
        {/* Hero Section */}
        <section className="about">
          <div
            className="tw-flex tw-w-full tw-h-full"
            style={{
              backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0.1)), url(${getFileUrl(backgroundImageUrl)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="max-container padding-x lg:hero-div hero-div-sm">
              <p className="tw-text tw-text-white">About Us</p>
              <h1 className="title tw-text-white tw-mt-4 md:tw-max-w-3xl" dangerouslySetInnerHTML={parseHTML(aboutCMS?.[0]?.title)}></h1>
              <p className="about-description tw-mt-4">
                {removeHTML(aboutCMS?.[0]?.description)}
              </p>
            </div>
          </div>
        </section>

        {/* Paragraph Sections */}
        <section className="max-container padding-x tw-py-24">
          {/* Who We Are */}
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-10 tw-w-full tw-justify-between tw-pb-24">
            <div className="tw-flex tw-flex-col tw-justify-center lg:tw-w-[50%] tw-w-full">
              <h3 className="title">{aboutCMS?.[1]?.title}</h3>
              <p className="about-desc" dangerouslySetInnerHTML={parseHTML(aboutCMS?.[1]?.description)}>
                {/* {removeHTML(aboutCMS?.[1]?.description)} */}
              </p>
            </div>
            <div className="tw-flex tw-w-full lg:tw-w-[46%] tw-h-[368px]">
              <img
                src={getFileUrl(aboutCMS?.[1]?.image)}
                className="tw-object-cover tw-h-full"
                alt="who we are"
              />
            </div>
          </div>

          {/* Why Choose Us */}
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-10 tw-w-full tw-justify-between md:tw-pt-24">
            {/* Description Section */}
            <div className="tw-flex tw-flex-col tw-justify-center lg:tw-w-[50%] tw-w-full">
              <p className="title">{aboutCMS?.[2]?.title}</p>
              <h3 className="about-desc" dangerouslySetInnerHTML={parseHTML(aboutCMS?.[2]?.description)}>
                {/* {removeHTML(aboutCMS?.[2]?.description)} */}
              </h3>
            </div>

            {/* Image Section (Mobile) */}
            <div className="tw-flex tw-w-full lg:tw-w-[46%] tw-h-[368px] md:tw-order-first">
              <img
                src={getFileUrl(aboutCMS?.[2]?.image)}
                className="tw-object-cover tw-h-full"
                alt="who choose us"
              />
            </div>
          </div>

        </section>

        {/* Team Section */}
        <section className="max-container padding-x md:tw-py-24">
          <div className="tw-flex tw-flex-col tw-items-center">
            <p className="top-title">{aboutCMS?.[3]?.title}</p>
            <h3 className="title">{removeHTML(aboutCMS?.[3]?.description)}</h3>
          </div>

          {/* Slick Slider */}
          <div className="tw-mt-12 padding-x tw-flex tw-justify-center">
            <Slider
              {...settings}
              className="max-container tw-w-full tw-h-full slider-container"
            >
              {team?.map((team) => {
                const { id, image, name, designation, facebook, linkedin } =
                  team;
                return (
                  <div
                    key={id}
                    className="slick-slide tw-flex tw-justify-center tw-w-full tw-cursor-pointer tw-text-center tw-group"
                  >
                    <div className="tw-flex tw-h-[324px] tw-relative tw-z-0">
                      <img
                        src={getFileUrl(image)}
                        className="tw-object-cover tw-w-full tw-h-full tw-rounded-md"
                        alt="team"
                      />
                      {/* <div className="tw-absolute tw-bottom-0 tw-left-0 tw-top-0 tw-z-10 tw-transition tw-ease-in-out delay-150 group-hover:gradient-background">
                        <div className="tw-invisible tw-transition tw-ease-in-out group-hover:tw-visible tw-flex tw-h-full tw-justify-center tw-items-end tw-gap-2.5">
                          <span className="tw-flex tw-justify-center tw-items-center tw-border tw-border-[#FFFFFF] tw-rounded-full tw-w-[28px] tw-h-[28px]">
                            <a href={facebook}>
                              <FaFacebookF className="tw-w-[20px] tw-h-[20px] tw-text-[#FFFFFF]" />
                            </a>
                          </span>
                          <span className="tw-flex tw-justify-center tw-items-center tw-border tw-border-[#FFFFFF] tw-rounded-full tw-w-[28px] tw-h-[28px]">
                            <a href={linkedin}>
                              <FaLinkedinIn className="tw-w-[20px] tw-h-[20px] tw-text-[#FFFFFF]" />
                            </a>
                          </span>
                        </div>
                      </div> */}
                    </div>

                    <h3 className="tw-pt-6 tw-text-xl tw-text-black text-font-medium">
                      {name}
                    </h3>
                    <p className="tw-pt-1 tw-text-lg tw-text-[#787878]">
                      {removeHTML(designation)}
                    </p>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>

        {/* Benefits Section */}
        {/* <section className="tw-bg-[#E9F5F7]">
          <div className="max-container padding-x tw-py-24">
            <div className="tw-flex tw-flex-col tw-items-center">
              <p className="top-title">{aboutCMS?.[9]?.title}</p>
              <h3 className="title">
                {removeHTML(aboutCMS?.[9]?.description)}
              </h3>
            </div>

            <div className="tw-mt-14 tw-flex tw-flex-wrap tw-gap-12 tw-justify-center">
              {benefits?.map((benefit) => {
                const { id, title, description } = benefit;
                return (
                  <div
                    key={id}
                    className="tw-group tw-cursor-pointer tw-flex tw-flex-col tw-w-full md:tw-w-[30%] tw-items-center tw-text-center"
                  >
                    <span className="tw-transition-all group-hover:tw-bg-[#45035a] tw-flex tw-items-center tw-justify-center tw-border tw-border-[#45035a] tw-rounded-full tw-w-[60px] tw-h-[60px]">
                      <GiMeditation className="group-hover:tw-text-[#FFFFFF] tw-text-[#45035a] tw-w-[32px] tw-h-[32px]" />
                    </span>
                    <h3 className="benefit-title">{title}</h3>
                    <p className="tw-text-base tw-text-[#424242]">
                      {removeHTML(description)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </section> */}

        {/* Team Section */}
        <section className="tw-bg-[#E9F5F7]">
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-12 max-container tw-py-24 padding-x">
            <div className="tw-flex tw-flex-col tw-justify-center tw-w-full md:tw-w-[50%]">
              <h3 className="title">{aboutCMS?.[4]?.title}</h3>
              <p className="tw-text-base tw-text-[#424242] tw-mt-4 tw-mb-8" dangerouslySetInnerHTML={parseHTML(aboutCMS?.[4]?.description)}>
                {/* {removeHTML(aboutCMS?.[4]?.description)} */}
              </p>
              <div>
                <button className="btn-orange" onClick={navigateToCareer}>
                  Let's Work Together
                </button>
              </div>
            </div>
            <div className="tw-flex tw-w-full md:tw-w-[46%] tw-h-[480px]">
              <img
                src={getFileUrl(aboutCMS?.[4]?.image)}
                className="tw-object-cover"
                alt="team background"
              />
            </div>
          </div>
        </section>
        {/* Gallery Section */}
        <div className="tw-bg-[#FAFAFA] tw-py-24">
          <div className="max-container padding-x ">
            <div className="tw-flex tw-flex-col tw-items-center">
              <p className="top-title">{aboutCMS?.[5]?.title}</p>
              <h3 className="title">
                {removeHTML(aboutCMS?.[5]?.description)}
              </h3>
            </div>
            <div className="tw-grid tw-grid-cols-1  md:tw-grid-cols-4 tw-gap-6 tw-mt-12">
              {teamFirst?.map((team, index) => {
                // const { id, image } = gallery;

                let colSpanClass = "tw-col-span-1"; // Default span for all columns
                if (index === 1) {
                  colSpanClass = " tw-col-span-1 md:tw-col-span-2"; // Span 2 columns for the second image
                }
                return (
                  <div key={index} className={`tw-h-[328px] ${colSpanClass}`}>
                    <img
                      src={getFileUrl(team)}
                      className="tw-h-full tw-w-full tw-object-cover"
                      alt="gallery"
                    />
                  </div>
                );
              })}
            </div>

            <div className="tw-grid md:tw-grid-cols-2 tw-gap-6 tw-mt-6">
              {teamSecond?.map((team, index) => {
                // const { id, image } = gallery;
                return (
                  <div key={index} className="tw-h-[328px]">
                    <img
                      src={getFileUrl(team)}
                      className="tw-h-full tw-w-full tw-object-cover"
                      alt="gallery"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </main >
    </>
  );
}

export default About;
