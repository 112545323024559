import axios from "../utils/axios";

export const getDestinations = async (take) => {
  const params = new URLSearchParams();
  params.set("take", take);
  params.set("skip", "0");
  params.set("filterBy", "all");

  const response = await axios.get("/destinations", { params });
  return await response?.data?.data;
};

export const getDestination = async (id) => {
  const params = new URLSearchParams();
  params.append("id", id);

  const response = await axios.get(`/destinations/${id}`, { params });
  return await response?.data?.data;
};
