import axios from "../../utils/axios";

export const getTeam = async (take) => {
  const params = new URLSearchParams();
  params.set("take", take);
  params.set("skip", "0");
  params.set("filterBy", "all");

  const response = await axios.get("/get-teams", {
    params,
  });
  return await response?.data?.data;
};

export const getGallery = async (take) => {
  const params = new URLSearchParams();
  params.set("take", take);
  params.set("skip", "0");
  params.set("filterBy", "all");

  const response = await axios.get("/get-gallery", { params });
  return await response?.data?.data;
};
