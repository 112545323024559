import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

// import Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MdOutlineAttachFile } from "react-icons/md";

// utils
import { ParseHTML, removeHTML } from "../../utils/util";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";

// services
import {
  getCareer,
  submitCareerForm,
} from "../../services/career/career.services";
import { getCMS } from "../../services/home/home.services";

const career_slug = "career-page";
const DEFAULT_PAGE_SIZE = 4;

const CareerDetail = () => {
  const fileInputRef = useRef(null);

  const { slug } = useParams();

  const initialUserData = {
    name: "",
    email: "",
    position: "",
    coverLetter: "",
    file: "",
  };

  const [data, setData] = useState(initialUserData);
  const [selectedFile, setSelectedFile] = useState(null);

  // Mutations
  const mutation = useMutation({
    mutationFn: submitCareerForm,

    onSuccess: (data) => {
      toast.success(data);

      console.log(data);
      // clear the form after successful submission
      // setData({
      //   email: "",
      //   name: "",
      //   position: "",
      //   coverLetter: "",
      //   file: "",
      // });
    },
    onError: (err) => {
      toast.error(err);
    },
  });

  // Queries
  const { data: career, isPending: careerLoading } = useQuery({
    queryKey: ["career", slug],
    queryFn: () => getCareer(slug, 1),
  });
  const { data: careerCMS, isPending: careerCMSLoading } = useQuery({
    queryKey: ["career-cms", career_slug],
    queryFn: () => getCMS(career_slug, DEFAULT_PAGE_SIZE),
  });

  if (careerLoading || careerCMSLoading) {
    return <LoadingSpinner />;
  }

  // function to handle input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  // func to handle the cv upload
  const handleButtonClick = (e) => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Handle form submission, including file upload
    const formData = new FormData();

    formData.append("email", data.email);
    formData.append("name", data.name);
    formData.append("position", career?.title);
    formData.append("coverLetter", data.coverLetter);
    formData.append("file", selectedFile);

    // Send formdata to your server or perform further actions

    // Call the mutation function to submit the form Data
    mutation.mutate(formData);
  };

  return (
    <>
      <Helmet>
        <title>{`Epark Consultancy | ${career?.title}`}</title>
      </Helmet>

      {/* Career Detail Hero Section */}
      <section className="div-wrapper max-container padding-x tw-mb-36">
        <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-pt-[72px]">
          <p className="top-title">{careerCMS?.[1]?.title}</p>
          <h3 className="title">{removeHTML(careerCMS?.[1]?.description)}</h3>
        </div>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-[58px] tw-justify-between tw-pt-20">
          <div className="tw-flex tw-flex-col tw-w-full lg:tw-w-1/2">
            <h2 className="career-bold-title">{career?.title}</h2>
            <div className="tw-flex tw-gap-3 tw-mt-3 tw-pb-6 tw-border-b tw-border-[#DEDEDE]">
              <p className="pill-orange">Figma</p>
              <p className="pill-orange">HTML</p>
              <p className="pill-orange">CSS</p>
            </div>

            <div className="tw-flex tw-flex-col tw-gap-4">
              {/* <h3 className="tw-text-lg tw-font-medium tw-pt-6">
                Job Description
              </h3> */}
              <div className="tw-pt-6">
                {/* <p className="tw-text-sm tw-font-medium tw-mb-2">
                  Key Qualifications
                </p> */}
                <ul className="tw-list-disc list-disc tw-flex tw-flex-col tw-gap-1 md:tw-pl-6">
                  {ParseHTML(career?.description)}
                </ul>
              </div>
              <div>
                {/* <p className="tw-text-sm tw-font-medium tw-mb-2">
                  Requirements
                </p> */}
                {/* <ul className="tw-list-disc tw-text-[#535353] tw-pl-6">
                  {ParseHTML(career?.requirements)}
                </ul> */}
              </div>

              <p className="tw-mt-10 tw-text-sm tw-text-[#787878]">
                *Note: Please also mention if it was made by you individually,
                or you worked on the portfolio project as a part of a team, and
                what your contribution was in the project.
              </p>
            </div>
          </div>

          {/* Career Detail Form */}
          <div className="tw-flex tw-flex-col tw-w-full lg:tw-w-1/2 tw-border tw-border-[#E8E8E8]">
            <form className="tw-p-5 md:tw-p-8" onSubmit={handleSubmit}>
              <p>
                Are you looking for a Full-time role or internship?
                <span className="tw-text-red-500"> *</span>
              </p>
              <input
                name="position"
                className="tw-mt-4 tw-mb-5 career-input tw-capitalize"
                type="text"
                value={career?.title}
                disabled
              />

              <p>
                Name
                <span className="tw-text-red-500"> *</span>
              </p>
              <input
                name="name"
                className="tw-mt-4 tw-mb-5 career-input"
                type="text"
                value={data?.name}
                onChange={handleInputChange}
                required
              />

              <p>
                Phone (With Country Code)
                <span className="tw-text-red-500"> *</span>
              </p>
              <input
                className="tw-mt-4 tw-mb-5 career-input"
                // name="position"
                type="text"
                // value={data.position}
                // onChange={handleInputChange}
                disabled
              />

              <p>
                Email
                <span className="tw-text-red-500"> *</span>
              </p>
              <input
                name="email"
                className="tw-mt-4 tw-mb-5 career-input"
                type="email"
                value={data?.email}
                onChange={handleInputChange}
                required
              />

              <p>Cover Letter</p>
              <textarea
                name="coverLetter"
                className="tw-mt-4 tw-mb-5 career-input focus:tw-border-[#45035a] tw-resize-none"
                type="text"
                value={data?.coverLetter}
                onChange={handleInputChange}
                required
              ></textarea>

              <p>
                Resume
                <span className="tw-text-red-500"> *</span>
              </p>
              <input
                type="file"
                ref={fileInputRef}
                accept=".pdf"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {/* Attach File Button */}
              <button
                type="button"
                className="tw-flex tw-bg-[#ECECEC] tw-rounded-sm tw-text-[#787878] tw-items-center tw-mt-4 tw-border tw-border-[#ECECEC] hover:tw-border-[#45035a]"
                onClick={handleButtonClick}
              >
                <MdOutlineAttachFile />
                {selectedFile ? (
                  selectedFile.name.length > 20 ? (
                    <>{`${selectedFile.name.substring(0, 20)}...`}</>
                  ) : (
                    selectedFile.name
                  )
                ) : (
                  "Attach File"
                )}
              </button>

              {/* Submit Button */}
              <div className="tw-flex tw-justify-end tw-mt-6">
                <button
                  className="tw-bg-[#45035a] tw-text-[#FFFFFF] tw-rounded-md"
                  type="submit"
                  disabled={mutation.isPending}
                >
                  {mutation.isPending ? "Submitting..." : "Submit"}
                </button>
                {mutation.isError && (
                  <div className="error-message">
                    An error occured while submitting the form.
                  </div>
                )}
              </div>
              <ToastContainer />
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerDetail;
