import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import { Tab } from "@headlessui/react";

import { CiSearch } from "react-icons/ci";

import "./careers.css";

import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";

// services
import { getCMS, getCMSPageTitle } from "../../services/home/home.services";
import {
  getCareerCategories,
  getCareers,
} from "../../services/career/career.services";
import CareerTable from "./CareerTable";

const DEFAULT_PAGE_SIZE = 4;
const career_slug = "career-page";

function Careers() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchText, setSearchText] = useState("");

  const scrollToCareerTable = () => {
    const careerTableSection = document.getElementById("careerTable");

    if (careerTableSection) {
      const offset = careerTableSection.offsetTop - 50;
      window.scrollTo({
        top: offset,
        behavior: "smooth", // for smooth scrolling
      });
    }
  };

  const handleSearch = (text) => {
    setSearchText(text.toLowerCase());
  };

  const filteredCareers = (career) => {
    return career.title.toLowerCase().includes(searchText);
  };

  // Queries
  const { data: careerCMS, isPending: careerCMSLoading } = useQuery({
    queryKey: ["career-cms", career_slug],
    queryFn: () => getCMS(career_slug, DEFAULT_PAGE_SIZE),
  });
  const { data: pageTitle, isPending: pageTitleLoading } = useQuery({
    queryKey: ["pagetitle-cms", career_slug],
    queryFn: () => getCMSPageTitle(career_slug, DEFAULT_PAGE_SIZE),
  });
  const { data: careers, isPending: careersLoading } = useQuery({
    queryKey: ["careers"],
    queryFn: () => getCareers(10),
  });
  const { data: careerCategories, isPending: careerCategoriesLoading } =
    useQuery({
      queryKey: ["career-categories"],
      queryFn: () => getCareerCategories(10),
    });

  if (
    careerCMSLoading ||
    pageTitleLoading ||
    careersLoading ||
    careerCategoriesLoading
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{`Epark Consultancy | ${pageTitle?.title}`}</title>
      </Helmet>

      <section className="careers" id="career">
        <div className="div-wrapper">
          <div
            className="tw-flex tw-w-full tw-h-[513px]"
            style={{
              backgroundImage: `url(${getFileUrl(careerCMS?.[0]?.image)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="max-container hero-div padding-x">
              <h1 className="title-hero">{careerCMS?.[0]?.title}</h1>
              <p className="title-desc-hero">
                {removeHTML(careerCMS?.[0]?.description)}
              </p>
              <div className="tw-mt-6">
                <button className="btn-orange" onClick={scrollToCareerTable}>
                  View current vacancies
                </button>
              </div>
            </div>
          </div>
          <div className="max-container padding-x tw-mt-[72px] tw-mb-32">
            <div className="tw-relative tw-flex tw-items-center tw-rounded-md tw-w-sm tw-mb-12">
              <input
                type="text"
                placeholder="Search"
                className="tw-outline-none tw-border-none focus:tw-border-none tw-w-sm tw-py-3 tw-px-8 tw-bg-[#F6F6F6]"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <CiSearch className="tw-absolute tw-left-3 tw-top-4 tw-text-[#535353] pointer-events-none" />
            </div>

            {/* Careers Tab Section */}
            <section id="careerTable">
              {careers && careers.length > 0 ? (
                <Tab.Group
                  selectedIndex={selectedIndex}
                  onChange={setSelectedIndex}
                >
                  <Tab.List
                    className="tw-flex tw-overflow-x-auto tw-justify-start tw-space-x-4 leading-5
                 md:tw-space-x-9 tw-text-[#C1C1C1] tw-border-b tw-border-[#EAEBEB]"
                  >
                    <Tab
                      key={selectedIndex}
                      className={`tw-px-0 ${
                        selectedIndex === 0 ? "tab-active" : "tab-active-hover"
                      }`}
                    >
                      All
                    </Tab>
                    {careerCategories?.map((careerCategory) => {
                      const { id, category } = careerCategory;
                      return (
                        <Tab
                          key={id}
                          className="tab-active-hover tw-px-0 tw-capitalize"
                        >
                          {category}
                        </Tab>
                      );
                    })}
                  </Tab.List>

                  <Tab.Panels>
                    {/* All Careers Panel */}
                    <Tab.Panel className="tw-flex tw-flex-col tw-gap-12 tw-pt-10">
                      {careers?.filter(filteredCareers).map((career) => {
                        return <CareerTable career={career} key={career.id} />;
                      })}
                    </Tab.Panel>

                    {/* Render Careers for Each Category */}
                    {careerCategories?.map((category) => {
                      return (
                        <Tab.Panel
                          id={category?.id}
                          className="tw-flex tw-flex-col tw-gap-12 tw-justify-center tw-pt-10"
                        >
                          {careers
                            ?.filter(
                              (career) => career.category === category.category
                            )
                            .map((career) => {
                              return (
                                <CareerTable career={career} key={career.id} />
                              );
                            })}
                        </Tab.Panel>
                      );
                    })}
                  </Tab.Panels>
                </Tab.Group>
              ) : (
                <h4 className="text-secondary text-center">
                  There are no vacancies right now. Please visit later.
                </h4>
              )}
            </section>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careers;
