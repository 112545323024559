import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { CiGlobe } from "react-icons/ci";

export default function PortfolioIcons({ item }) {
  const { facebook, instagram, linkedin } = item;

  return (
    <div className="tw-flex tw-flex-row tw-gap-2 md:tw-gap-5">
      <a
        href={`https://${facebook}`}
        target="_blank"
        className="tw-group hover:tw-bg-[#2585EB] tw-bg-[#E5F0FD] button-transition icon-portfolio"
        rel="noreferrer"
      >
        <span className="tw-text-[#2585EB] group-hover:tw-text-[#E5F0FD]">
          <CiFacebook className="tw-w-[18px] tw-h-[18px]" />
        </span>
        <p className="tw-text-xs tw-text-[#2585EB] group-hover:tw-text-[#E5F0FD]">
          Facebook
        </p>
      </a>

      <a
        href={`https://${instagram}`}
        target="_blank"
        className="tw-group hover:tw-bg-black tw-bg-[#F2F2F2] button-transition icon-portfolio"
        rel="noreferrer"
      >
        <span className="tw-text-black group-hover:tw-text-[#F2F2F2]">
          <FaInstagram className="tw-w-[18px] tw-h-[18px]" />
        </span>
        <p className="tw-text-xs group-hover:tw-text-[#F2F2F2]">Instagram</p>
      </a>

      <a
        href={`https://${linkedin}`}
        target="_blank"
        className="tw-group hover:tw-bg-[#FF733A] tw-bg-[#FFF1E8] button-transition icon-portfolio"
        rel="noreferrer"
      >
        <span className="tw-text-[#FF733A] group-hover:tw-text-[#FFF1E8]">
          <CiGlobe className="tw-w-[18px] tw-h-[18px]" />
        </span>
        <p className="tw-text-xs tw-text-[#FF733A] group-hover:tw-text-[#FFF1E8]">
          Website
        </p>
      </a>
    </div>
  );
}
