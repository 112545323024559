import axios from "../../utils/axios";

export const getCareers = async (take) => {
  const params = new URLSearchParams();
  params.set("take", take);
  params.set("skip", "0");
  params.set("filterBy", "all");
  const response = await axios.get("/careers", { params });
  return await response?.data?.data;
};

export const getCareerCategories = async (take) => {
  const params = new URLSearchParams();
  params.set("take", take);
  params.set("skip", "0");
  params.set("filterBy", "all");
  const response = await axios.get("/careers/category", { params });
  return await response?.data?.data;
};

export const getCareer = async (id) => {
  const params = new URLSearchParams();

  const response = await axios.get(`careers/${id}`, { params });
  return await response?.data?.data;
};

export const submitCareerForm = async (formData) => {
  const response = await axios.post("/careers/applications", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response?.data?.message;
};
