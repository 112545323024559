import { useEffect, useState } from "react";
import "./training.css";
import quotation from "./quotation-mark.png";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { RxEnvelopeClosed } from "react-icons/rx";
import { TbPhone } from "react-icons/tb";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from "@tanstack/react-query";
import HTMLRenderer from 'react-html-renderer'

import {
  getCMS
} from "../../services/home/home.services";


function Training() {
  const [trainingVideos, setTrainingVideos] = useState([]);
  const [trainingCategory, setTrainingCategory] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [trainingCMS, setTrainingCMS] = useState([]);
  const [trainingChildCMS, setTrainingChildCMS] = useState([]);
  const [trainingBookCMS, setTrainingBookChildCMS] = useState({});
  const [phoneCMS, setPhoneCMS] = useState({});
  const [contactCMS, setContactCMS] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [emailError, setEmailError] = useState({});
  const [subjectError, setSubjectError] = useState({});

  const fetchTrainings = async () => {
    const params = new URLSearchParams();
    params.set("take", (8).toString());
    setIsLoading(true);
    const res = await makeRequest(`/trainings?filterBy=all`);
    if (res) {
      setTrainingVideos(res.data?.data);
      setIsLoading(false);
    }

    setIsLoading(true);
    const resp = await makeRequest(`/cms/courses`);
    if (resp) {
      setTrainingCMS(resp.data?.data);
      setTrainingChildCMS(resp.data?.data.childPages[0]);
      setTrainingBookChildCMS(resp.data?.data.childPages[1]);
      setPhoneCMS(resp.data?.data.childPages[2]);
      setContactCMS(resp.data?.data.childPages[3]);
      setIsLoading(false);
    }
  };

  const fetchCategory = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/trainings/category?filterBy=all`);
    if (res) {
      setTrainingCategory(res.data?.data);
      setIsLoading(false);
    }
  };

  const banner_slug = "course-banner";
  const DEFAULT_PAGE_SIZE = 4;

  const fetchItems = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/testimonials`);
    if (res) {
      const data = res?.data?.data;
      const filter = data
        .filter((data) => data.category === "Academy")
        .splice(0, 2);
      setTestimonials(filter);
      setIsLoading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: "button__bar testimonial-pagination",
    cssEase: 'steps(0,end)',
    variableWidth: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchTrainings();
    fetchCategory();
    fetchItems();
  }, []);

  const emailValidation = () => {
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (regex.test(email) === false) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = formValidation();

    if (isValid) {
      const data = {
        email: email,
        trainingName: subject,
      };

      console.log(data);
      const res = await makeRequest(`/trainings/application`, {
        method: "POST",
        data: data,
      });
      if (res) {
        toast.success(res.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };

  const decodeHtmlEntities = (html) => {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent;
  };

  const parseHTML = (htmlString) => {
    return { __html: htmlString };
  };

  const formValidation = () => {
    const emailError = {};
    const subjectError = {};
    let isValid = true;

    if (email === "") {
      emailError.emptyEmail = "Email field cannot be empty!";
      isValid = false;
    } else if (emailValidation() === false) {
      setEmailError("Invalid Email Address!");
    }
    if (subject === "") {
      subjectError.emptySubject = "Please select a course!";
      isValid = false;
    }

    setEmailError(emailError);
    setSubjectError(subjectError);
    return isValid;
  };

  const { data: banners, isPending: bannerCMSLoading } = useQuery({
    queryKey: ["banners", banner_slug],
    queryFn: () => getCMS(banner_slug, DEFAULT_PAGE_SIZE),
  });
  console.log('banners', banners)

  if (bannerCMSLoading
  ) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Helmet>
        <title>Courses</title>
        <meta name="description" content={trainingCMS.metaDescription} />
      </Helmet>
      <main>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <section className="training-home" id="training-home">
              <div className="container-fluid training-home-wrapper">
                {trainingCMS.title && (
                  <>
                    <div className="content-header tw-mb-2">
                      <h1 className="col-lg-5 col-8 heading">
                        {trainingCMS.description &&
                          removeHTML(trainingCMS.description)}
                      </h1>
                    </div>
                  </>
                )}
                <Slider {...settings} className="tw-w-[80vw] tw-m-auto">
                  {banners?.map((banner) => {
                    const { id, title, image, name, description, designation } =
                      banner;
                    const decodedHtmlContent = decodeHtmlEntities(description);
                    return (
                      <div className="tw-flex">
                        <div
                          className="tw-flex tw-justify-center tw-items-center tw-w-full"
                          style={{
                            backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.05)), url(${getFileUrl(image)})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: `60vh`
                          }}
                        >
                          <div className="max-container tw-w-full tw-text-left tw-flex tw-flex-col tw-items-left padding-x tw-bg-blend-darken">
                            <h1 className="md:tw-max-w-4xl">
                              <span className="tw-text-[#ffffff] title tw-py-12" dangerouslySetInnerHTML={parseHTML(title)}>
                              </span>
                            </h1>
                            <div className="md:tw-max-w-2xl tw-mt-4">
                              <HTMLRenderer html={decodedHtmlContent} />

                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </section>

            <section className="training-videos">
              <div className="container training-videos-wrapper">
                <div className="dropdown text-center">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Category
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {trainingCategory.map((category, index) => (
                      <li key={index}>
                        <div className="dropdown-item">{category.category}</div>
                      </li>
                    ))}
                  </ul>
                </div>
                {trainingCategory.length > 0 && (
                  <div className="navbar">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li
                        key={trainingCategory[0].id}
                        className="nav-item"
                        role="presentation"
                      >
                        <button
                          className="nav-link active"
                          id={trainingCategory[0].id}
                          data-bs-toggle="tab"
                          data-bs-target={`#${trainingCategory[0].slug}`}
                          type="button"
                          role="tab"
                          aria-controls={trainingCategory[0].slug}
                          aria-selected="false"
                        >
                          {trainingCategory[0].category}
                        </button>
                      </li>
                      {trainingCategory
                        .filter(
                          (training) => training.id !== trainingCategory[0].id
                        )
                        .map((training, id) => {
                          return (
                            <li
                              key={id}
                              className="nav-item"
                              role="presentation"
                            >
                              <button
                                className="nav-link"
                                id={training.id}
                                data-bs-toggle="tab"
                                data-bs-target={`#${training.slug}`}
                                type="button"
                                role="tab"
                                aria-controls={training.slug}
                                aria-selected="false"
                              >
                                {training.category}
                              </button>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" role="tabpanel">
                    <div className="content-main mt-4">
                      <div className="grid-container">
                        {trainingVideos
                          .filter((training) =>
                            training.category.includes(
                              trainingCategory[0].category
                            )
                          )
                          .map((training, id) => {
                            return (
                              <Link
                                to={`/course/${training.slug}`}
                                key={id}
                                className="card mb-3"
                                style={{ maxWidth: 540 }}
                              >
                                <div className="row g-2">
                                  <div className="col-md-4">
                                    <img
                                      src={
                                        training.image
                                          ? getFileUrl(training.image)
                                          : "../assets/images/training-default.jpg"
                                      }
                                      className="img-fluid rounded-start"
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-md-8">
                                    <div className="card-body">
                                      <h5 className="card-title">
                                        {training.title}
                                      </h5>
                                      <p className="card-text">
                                        {removeHTML(training.shortDescription)}
                                      </p>
                                      {training.tags !== "undefined" ? (
                                        <div className="tags gap-2 mb-2 d-flex flex-wrap">
                                          {training.tags
                                            .split(",")
                                            .map((tag, index) => (
                                              <small
                                                className="card-text"
                                                key={index}
                                              >
                                                {tag}
                                              </small>
                                            ))}
                                        </div>
                                      ) : (
                                        <small
                                          style={{
                                            background: "white",
                                          }}
                                        ></small>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {trainingCategory.map((category, id) => {
                    return (
                      <div
                        className="tab-pane fade"
                        id={category.slug}
                        key={id}
                        role="tabpanel"
                        aria-labelledby={category.id}
                      >
                        <div className="content-main mt-4">
                          <div className="grid-container">
                            {trainingVideos
                              .filter((training) =>
                                training.category.includes(category.category)
                              )
                              .map((training, id) => {
                                return (
                                  <Link
                                    to={`/course/${training.slug}`}
                                    key={id}
                                    className="card mb-3"
                                    style={{ maxWidth: 540 }}
                                  >
                                    <div className="row g-2">
                                      <div className="col-md-4">
                                        <img
                                          src={
                                            training.image
                                              ? getFileUrl(training.image)
                                              : "../assets/images/training-default.jpg"
                                          }
                                          className="img-fluid rounded-start"
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-md-8">
                                        <div className="card-body">
                                          <h5 className="card-title">
                                            {training.title}
                                          </h5>
                                          <p className="card-text">
                                            {removeHTML(
                                              training.shortDescription
                                            )}
                                          </p>
                                          {training.tags !== "undefined" ? (
                                            <div className="tags gap-2 mb-2 d-flex flex-wrap">
                                              {training.tags
                                                .split(",")
                                                .map((tag, index) => (
                                                  <small
                                                    className="card-text"
                                                    key={index}
                                                  >
                                                    {tag}
                                                  </small>
                                                ))}
                                            </div>
                                          ) : (
                                            <small
                                              style={{
                                                background: "white",
                                              }}
                                            ></small>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
            {testimonials.length > 0 && (
              <section
                className="training-testimonials"
                id="training-testimonials"
              >
                <div className="container training-testimonials-wrapper">
                  <div className="content-header text-center">
                    <span>Meet Our Team Members</span>
                    <h1 className="heading">What our team says about Epark </h1>
                  </div>
                  <div className="content-bottom">
                    {testimonials.map((item, index) => {
                      return (
                        <div key={index} className="quote-left">
                          <img className="quotation" src={quotation} alt="" />
                          <p className="subheading">{item.description}</p>
                          <div className="profile">
                            <div className="profile-details">
                              <h2 className="name">{item.name}</h2>
                              <h3 className="position">{item.designation}</h3>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            )}
            {trainingBookCMS && (
              <section className="book " id="book">
                <div className="container book-wrapper">
                  <div className="row content">
                    <div className="col-lg-7 content-left">
                      <img
                        className="img-fluid"
                        src={
                          trainingBookCMS.image
                            ? getFileUrl(trainingBookCMS.image)
                            : "../assets/images/bg-booking.png"
                        }
                        alt=""
                      ></img>
                    </div>
                    <div className="col-lg-5 content-main ">
                      <h1>{trainingBookCMS.title}</h1>
                      <p>
                        {trainingBookCMS.description &&
                          removeHTML(trainingBookCMS.description)}
                      </p>
                      <div className="mt-3 booking-options">
                        <div
                          style={{
                            border: "1px solid",
                            "border-radius": "0.25rem",
                            height: "50px",
                            width: window.innerWidth <= 574 ? "97%" : "50%",
                          }}
                        >
                          <select
                            className="btn dropdown-toggle"
                            id="subject"
                            name="subject"
                            style={{ padding: "0.7rem", width: "100%" }}
                            onChange={(e) => setSubject(e.target.value)}
                          >
                            <option value="" selected disabled>
                              Please select
                            </option>
                            {trainingVideos.map((training, id) => {
                              return (
                                <option key={id} value={training.title}>
                                  <div className="dropdown-item">
                                    {training.title}
                                  </div>
                                </option>
                              );
                            })}
                          </select>
                          <div className="col">
                            {Object.keys(subjectError).map((key) => {
                              return (
                                <div
                                  key={subjectError}
                                  style={{ color: "red", fontSize: "small" }}
                                >
                                  {subjectError[key]}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <form className="row g-3" onSubmit={handleSubmit}
                          style={{
                            width: window.innerWidth <= 574 ? "100%" : "50%"
                          }}
                        >
                          <div className="col-auto tw-w-full">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              style={{
                                margin: "0 0", padding: "12px 8px", border: "1px solid black",
                              }}
                              className="tw-rounded-md"
                            />
                            <div className="col">
                              {Object.keys(emailError).map((key) => {
                                return (
                                  <div
                                    key={emailError}
                                    style={{ color: "red", fontSize: "small" }}
                                  >
                                    {emailError[key]}
                                  </div>
                                );
                              })}
                            </div>
                            <div class="tw-flex tw-justify-end tw-mt-1">
                              <button type="submit" className="py-2 px-0 tw-shadow-lg tw-text-white" style={{ width: "90px", background: "#4d61ac" }}>Book</button>
                            </div>
                          </div>

                          <ToastContainer />
                        </form>
                      </div>
                      <div className="contact-details">
                        <h2 className="heading">Or, contact us at:</h2>
                        <span className="number d-flex align-items-center">
                          <RxEnvelopeClosed />
                          &nbsp;
                          {phoneCMS.description &&
                            removeHTML(phoneCMS.description)}
                        </span>
                        <span className="email d-flex align-items-center">
                          <TbPhone />
                          &nbsp;
                          {contactCMS.description &&
                            removeHTML(contactCMS.description)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </main>
    </>
  );
}

export default Training;
