import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./pop-up.css";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import ReactQuill from "react-quill";
import { useEffect } from "react";
import { removeHTML } from "../../utils/util";

function PopUp(props) {
  const [show, setShow] = useState(false);
  const [popUp, setPopUp] = useState([]);
  const [popUpCms, setPopUpCms] = useState([]);

  const fetchData = async () => {
    const res = await makeRequest(`/popup`);
    if (res) {
      setPopUp(res?.data?.data);
    }
  };

  const fetchCmsData = async () => {
    const res = await makeRequest(`/cms/pop-up`);
    if (res) {
      setPopUpCms(res?.data?.data.childPages);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);

    fetchData();
    fetchCmsData();
  }, [setShow]);

  const handleClose = () => setShow(false);
  if (popUp?.isActive != 1) {
    return null;
  }
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        <div className="popup">
          <div>
            <p className="btn-close" onClick={handleClose} />
          </div>
            {/* <h1 className="pop-title">{popUp.title}</h1> */}

          <img
            src={
              popUp.image
                ? getFileUrl(popUp.image)
                : "../assets/images/popup.png"
            }
            className="pop-image img-fluid"
            style={{ objectFit: "cover" }}
            alt=""
          ></img>
        </div>
      </Modal>
    </>
  );
}

export default PopUp;
