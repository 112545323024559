import axios from "axios";

const makeRequest = async (url, options) => {
  try {
    return await axios(`${process.env.REACT_APP_API_URL}${url}`, {
      ...options,
    });
  } catch (error) {
    let errorMessage = error?.message;

    const dataMsg = error?.response?.data?.message;
    if (dataMsg) {
      if (Array.isArray(dataMsg)) errorMessage = dataMsg[0];
      else errorMessage = dataMsg;
    }
  }
};

export default makeRequest;
