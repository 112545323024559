import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import "./contact-us.css";

import makeRequest from "../../utils/api";
import { removeHTML } from "../../utils/util";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdMail, IoMdCall, IoIosPin } from "react-icons/io";
import { getDestinations } from "../../services/destinations";
import { useQuery } from "@tanstack/react-query";
import Select from 'react-select';



import getFileUrl from "../../utils/getFileUrl";

function ContactUs({ data }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setContact] = useState("");
  const [nameError, setNameError] = useState({});
  const [emailError, setEmailError] = useState({});
  const [mobileNumError, setMobileNumError] = useState({});
  const [contactUsCms, setContactUsCms] = useState([]);
  const [contactUsChildCms, setContactUsChildCms] = useState([]);
  const [admissionFormCms, setAdmissionFormCms] = useState([]);
  const [selectedDestinations, setSelectedDestinations] = useState([]);

  const selectedDestinationIds = selectedDestinations.map((destination) => destination.value);


  const { id, slug } = useParams();
  const location = useLocation();

  const { data: destinations, isPending: destinationsLoading } = useQuery({
    queryKey: ["destinations"],
    queryFn: () => getDestinations(10),
  });

  const options = destinations?.map((destination) => ({
    value: destination.title,
    label: destination.title,
  })) || [];

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/contact-us`);
    if (res) {
      setContactUsCms(res.data?.data);
      setContactUsChildCms(res.data?.data.childPages);
    }

    const response = await makeRequest(`/cms/admission-form`);
    if (response) {
      setAdmissionFormCms(response.data?.data.childPages);
    }
  };

  useEffect(() => {
    fetchCMS();
  }, []);

  const nameValidation = () => {
    if (!name.match(/^[a-zA-Z\s]+$/)) {
      return false;
    }
    return true;
  };

  const numberValidation = () => {
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (regex.test(phone) === false) {
      return false;
    }
    return true;
  };

  const emailValidation = () => {
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (regex.test(email) === false) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = formValidation();
    if (!isValid) return;

    if (isValid) {
      const data = {
        name: name,
        email: email,
        subject: subject,
        message: message,
        phone: phone,
        destination: selectedDestinationIds,
      };

      if (id) {
        const data = {
          name: name,
          email: email,
          trainingName: id,
          subject: id,
          message: message,
          destination: selectedDestinationIds,
        };
        const res = await makeRequest(`/trainings/application`, {
          method: "POST",
          data: data,
        });
        if (res) {
          toast.success(res?.data?.message);
          setTimeout(() => {
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setContact("");
            setSelectedDestinations([]);
          }, 3000);
        }
      } else {
        const res = await makeRequest(`/contact-us`, {
          method: "POST",
          data: data,
        });
        if (res) {
          toast.success(res?.data?.message);
          setTimeout(() => {
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setContact("");
          }, 3000);
        }
      }
    }
  };

  const formValidation = () => {
    const nameError = {};
    const emailError = {};
    const mobileNumError = {};
    let isValid = true;

    if (nameValidation() === false) {
      nameError.invalidName = "Name must contain letters only!";
      isValid = false;
    }
    if (emailValidation() === false) {
      setEmailError("Invalid Email Address!");
      isValid = false;
    }
    // if (id) {
    if (numberValidation() === false) {
      mobileNumError.invalidNumber = "Phone number must be 10 digits!";
      isValid = false;
    }
    // }
    setNameError(nameError);
    setEmailError(emailError);
    setMobileNumError(mobileNumError);
    return isValid;
  };

  const handleSubject = (e) => {
    if (id) {
      setSubject(id);
    } else {
      setSubject(e.target.value);
    }
  };

  // Check if the current route matches '/'
  const isContactRoute = location.pathname === "/contact-us";
  const isHomeRoute = location.pathname === "/";
  const isServiceDetailRoute =
    location.pathname.startsWith("/services/") && slug;

  const isHomeOrServiceDetailRoute = isHomeRoute || isServiceDetailRoute;

  const bgImageStyle = {
    ...(isHomeOrServiceDetailRoute && {
      backgroundImage: `url(${getFileUrl(contactUsCms?.image)})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }),
  };

  const iconMapping = {
    email: <IoMdMail />,
    "Contact number": <IoMdCall />,
    location: <IoIosPin />,
  };
  return (
    <div className="tw-flex tw-w-full tw-h-full" style={bgImageStyle}>
      <div
        className={` ${isContactRoute ? "tw-pt-5 tw-pb-24" : " tw-py-24"
          } max-container tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-gap-12 lg:tw-gap-0 tw-items-center tw-justify-between padding-x`}
      >
        <div className="tw-flex tw-flex-col tw-justify-center tw-w-full lg:tw-w-[36%]">
          <h1
            style={{ color: isContactRoute ? "#111111" : data.color }}
            className="tw-text-[32px] md:tw-text-5xl tw-font-semibold tw-w-full lg:tw-w-[506px]"
          >
            {id
              ? admissionFormCms[0]?.title
              : "Feel free to contact us."}
          </h1>
          <p
            className="tw-pt-4 tw-pb-10 tw-text-base tw-w-full lg:tw-w-[76%]"
            style={{ color: isContactRoute ? "#787878" : data.color }}
          >
            {id
              ? removeHTML(admissionFormCms[0]?.description)
              : removeHTML(contactUsCms?.description)}
          </p>
          {contactUsChildCms.length > 0 && (
            <div className="contact-details">
              <ul
                className="menu"
                style={{ color: isContactRoute ? "#787878" : data.color }}
              >
                {contactUsChildCms.map((child, index) => {
                  const IconComponent = iconMapping[child.title];

                  console.log("contact-us", contactUsChildCms, IconComponent);
                  return (
                    <li
                      key={index}
                      className="tw-flex tw-gap-2 tw-items-center"
                    >
                      <span>
                        {IconComponent &&
                          React.cloneElement(IconComponent, {
                            className: isContactRoute
                              ? "tw-text-[#4d61ac]"
                              : "tw-text-[#fff]",
                          })}
                      </span>
                      {"\u00a0\u00a0"}
                      {child.description && removeHTML(child.description)}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div
          className={`${isContactRoute ? "tw-border-[#DBDBDB]" : "tw-border-[#FFFFFF]"
            } tw-border tw-flex tw-flex-col tw-bg-[#FFF] tw-px-5 md:tw-px-9 tw-py-5 md:tw-py-10 tw-rounded-md tw-w-full lg:tw-w-[50%]`}
        >
          <h1 className="tw-text-xl tw-font-semibold">
            {id ? "Admission Form" : "Contact US"}
          </h1>
          <form
            onSubmit={handleSubmit}
            className="tw-flex tw-flex-col tw-gap-4 tw-pt-5"
          >
            <input
              required
              type="text"
              name="name"
              placeholder="Your Full Name"
              value={name}
              className="contact-input tw-border tw-border-[#C3C3C3] hover:tw-border-[#45035a] tw-pl-4 tw-w-full"
              onChange={(e) => setName(e.target.value)}
            />
            {Object.keys(nameError).map((key) => {
              return (
                <div
                  key={nameError}
                  style={{ color: "red", fontSize: "small" }}
                >
                  {nameError[key]}
                </div>
              );
            })}

            <input
              required
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              className="contact-input tw-border tw-border-[#C3C3C3] hover:tw-border-[#45035a] tw-pl-4 tw-w-full"
              onChange={(e) => setEmail(e.target.value)}
            />
            {Object.keys(emailError).map((key) => {
              return (
                <div
                  key={emailError}
                  style={{ color: "red", fontSize: "small" }}
                >
                  {emailError[key]}
                </div>
              );
            })}

            {/* {id ? (
              <input
                readOnly
                disabled
                required
                type="text"
                name="subject"
                placeholder="Subject"
                value={id ? id : subject}
                className="contact-input tw-border tw-border-[#C3C3C3] hover:tw-border-[#45035a] tw-pl-4 tw-w-full"
              />
            ) : (
              <input
                required
                type="text"
                name="subject"
                placeholder="Subject"
                value={id ? id : subject}
                className="contact-input tw-border tw-border-[#C3C3C3] hover:tw-border-[#45035a] tw-pl-4 tw-w-full"
                onChange={(e) => handleSubject(e)}
              />
            )} */}
            {/* {id && ( */}
            <div>
              <input
                required
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={phone}
                className="contact-input tw-border tw-border-[#C3C3C3] hover:tw-border-[#45035a] tw-pl-4 tw-w-full"
                onChange={(e) => setContact(e.target.value)}
              />
              {Object.keys(mobileNumError).map((key) => {
                return (
                  <div
                    key={mobileNumError}
                    style={{ color: "red", fontSize: "small" }}
                  >
                    {mobileNumError[key]}
                  </div>
                );
              })}
            </div>
            {/* )} */}

            <Select
              options={options}
              isMulti  // If you want to allow multiple selections
              placeholder="Select destination(s)"
              onChange={(selectedOption) => setSelectedDestinations(selectedOption)}
            />
            {!id && (
              <textarea
                placeholder="A short message"
                name="message"
                value={message}
                className="tw-border tw-border-[#C3C3C3] hover:tw-border-[#45035a] tw-pl-4 tw-pt-4 tw-w-full tw-resize-none"
                onChange={(e) => setMessage(e.target.value)}
              />
            )}
            <div>
              <button
                type="submit"
                className="tw-bg-[#45035a] tw-rounded-md tw-text-[#fff] tw-mt-5 hover:tw-text-[#45035a] hover:tw-bg-opacity-25 hover:tw-bg-[#16A0C1] tw-transition tw-ease-in-out"
              >
                {id ? "Book Your Seat" : "Send Message"}
              </button>
            </div>

            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
