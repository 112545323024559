import { useLocation } from "react-router-dom";
import ContactUs from "../../components/contact-us/contact-us";
import "./contact.css";

function Contact() {
  const location = useLocation();

  // check if the current route is the home route
  const isHomeOrServiceRoute =
    location.pathname === "/" || location.pathname.startsWith("/services/");

  const divStyle = {
    ...(isHomeOrServiceRoute && {
      marginTop: 0,
    }),
  };

  return (
    <>
      <section
        className={`${!isHomeOrServiceRoute ? "contact-us" : ""}`}
        style={divStyle}
      >
        <ContactUs data={{ color: "#FFFFFF" }}></ContactUs>
      </section>
    </>
  );
}

export default Contact;
