import { removeHTML } from "../../utils/util";
import { useNavigate } from "react-router-dom";

export default function CareerTable({ career }) {
  const { id, title, description, slug, requirements } = career;

  const navigate = useNavigate();

  const navigateToCareerDetail = (slug) => {
    window.scrollTo(0, 0);
    navigate(`/career-detail/${slug}`);
  };

  return (
    <div
      key={id}
      className="tw-flex tw-flex-col md:tw-flex-row tw-gap-3 tw-justify-between tw-w-full"
    >
      <div className="tw-flex tw-flex-col md:tw-w-3/4">
        <h3 className="career-title">{title}</h3>
        <p className="tw-pt-3 tw-pb-4 tw-text-[#535353] tw-w-full">
          {removeHTML(description)?.slice(0, 90)}...
        </p>
        <h4 className="tw-text-[#B2B2B2]">Requirements</h4>
        <p className="tw-text-[#535353] tw-pt-2-">
          {removeHTML(requirements).slice(0, 80)}...
        </p>
      </div>
      <div className="tw-flex tw-items-center md:tw-w-1/4 tw-justify-end">
        <button
          className="btn-career tw-bg-[#45035a] tw-text-[#FFFFFF] tw-rounded-md"
          onClick={() => navigateToCareerDetail(slug)}
        >
          Apply Now
        </button>
      </div>
    </div>
  );
}
