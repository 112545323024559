import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

import Contact from "../Contact/contact";
import Features from "../Home/Services/features";

// utils
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { removeHTML } from "../../utils/util";
import getFileUrl from "../../utils/getFileUrl";

import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { CiGlobe } from "react-icons/ci";

// services
import { getService } from "../../services/services.services";
import { getCMS, getPortfolio } from "../../services/home/home.services";

const service_slug = "services-page";
const DEFAULT_PAGE_SIZE = 4;

const parseHTML = (htmlString) => {
  return { __html: htmlString };
};

const ServiceDetail = () => {
  const { slug } = useParams();

  const navigate = useNavigate();

  const navigateToContact = () => {
    window.scrollTo(0, 0);
    navigate("/contact-us");
  };

  // Queries
  const { data: service, isPending: serviceLoading } = useQuery({
    queryKey: ["service", slug],
    queryFn: () => getService(slug),
  });
  const { data: serviceCMS, isPending: serviceCMSLoading } = useQuery({
    queryKey: ["service-cms", service_slug],
    queryFn: () => getCMS(service_slug, DEFAULT_PAGE_SIZE),
  });
  const { data: portfolio, isPending: portfolioLoading } = useQuery({
    queryKey: ["portfolio"],
    queryFn: () => getPortfolio(DEFAULT_PAGE_SIZE),
  });

  if (serviceLoading || serviceCMSLoading || portfolioLoading) {
    return <LoadingSpinner />;
  }

  const workingSteps = serviceCMS?.slice(4, 7);
  const serviceId = service.id;

  return (
    <>
      <Helmet>
        <title>{`Epark Consultancy | ${service?.title}`} </title>
      </Helmet>
      {/* Hero Section */}
      <section className="div-wrapper">
        <div
          className="tw-flex tw-w-full tw-h-full h-[513px]"
        // style={{
        //   backgroundImage: `url(${getFileUrl(serviceCMS?.[0]?.image)})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        // }}
        >

          <div className="max-container hero-div-sm padding-x">
            <p className="top-title-orange">Our Services</p>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-10 tw-w-full tw-justify-between tw-pb-24">
              <div className="tw-flex tw-flex-col tw-justify-center lg:tw-w-[50%] tw-w-full">
                <h3 className="title">{service?.title}</h3>
                <p className="about-desc" dangerouslySetInnerHTML={parseHTML(service?.description)}>
                  {/* {removeHTML(aboutCMS?.[1]?.description)} */}
                </p>
              </div>
              <div className="tw-flex tw-w-full lg:tw-w-[46%] tw-h-[368px]">
                <img
                  src={getFileUrl(service?.image)}
                  className="tw-object-cover tw-h-full"
                  alt="who we are"
                />
              </div>
            </div>
            <div className="tw-mt-6">
              <button className="btn-orange" onClick={navigateToContact}>
                Quick Enquiry
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <Contact data={{ color: "#FFFFFF" }}></Contact>

      {/* Services Section */}
      <div className="tw-py-24">
        <Features></Features>
      </div>
    </>
  );
};

export default ServiceDetail;
