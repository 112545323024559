import axios from "../../utils/axios";

export const getCMS = async (slug, limit) => {
  const params = new URLSearchParams();
  params.set("filterBy", "all");
  if (limit) params.set("limit", limit);
  const response = await axios.get(`/cms/${slug}`, { params });
  return await response?.data?.data?.childPages;
};

export const getCMSPageTitle = async (slug, limit) => {
  const params = new URLSearchParams();
  params.set("filterBy", "all");
  if (limit) params.set("limit", limit);
  const response = await axios.get(`/cms/${slug}`, { params });
  return await response?.data?.data;
};

export const getTestimonials = async (take) => {
  const params = new URLSearchParams();
  params.set("take", take);
  params.set("skip", "0");
  params.set("filterBy", "all");

  const response = await axios.get("/get-testimonials", {
    params,
  });
  return await response?.data?.data;
};

export const getPortfolio = async (take) => {
  const params = new URLSearchParams();
  params.set("take", take);
  params.set("skip", "0");
  params.set("filterBy", "all");

  const response = await axios.get("/get-portfolio", { params });
  return await response?.data?.data;
};

export const getDestinations = async (take) => {
  const params = new URLSearchParams();
  params.set("take", take);
  params.set("skip", "0");
  params.set("filterBy", "all");

  const response = await axios.get("/destinations", { params });
  return await response?.data?.data;
};
