import { useEffect, useState } from "react";
import "./blogs.css";
import HeadlineBlog from "./headlineBlog";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [blogCategory, setBlogCategory] = useState([]);
  const [newsLetterCMS, setNewsLetterCMS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBlogs = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/blogs`);
    if (res) {
      setBlogs(res.data?.data);
      setIsLoading(false);
    }
  };

  const fetchCMS = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/cms/signup-to-our-newsletters`);
    if (res) {
      setNewsLetterCMS(res.data?.data);
      setIsLoading(false);
    }
  };

  const fetchCategory = async () => {
    setIsLoading(true);
    const res = await makeRequest(`/blogs/category`);
    if (res) {
      setBlogCategory(res.data?.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchCategory();
    fetchCMS();
  }, []);

  return (
    <>
      <Helmet>
        <title>Blogs in Epark</title>
        <meta name="description" content="Explore blogs in Epark consultancy" />
      </Helmet>
      <main>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <HeadlineBlog></HeadlineBlog>
            {/* <section className="newsletter">
              <div className="newsletter-wrapper py-5">
                <div className="content">
                  <h1>{newsLetterCMS.title}</h1>
                  <p>
                    {newsLetterCMS.description &&
                      removeHTML(newsLetterCMS.description)}
                  </p>
                  <form className="row g-3 justify-content-center">
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your email"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </section> */}
            <section className="blogs">
              <div className="max-container blogs-wrapper padding-x">
                <div className="content-top">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="all-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#all"
                        type="button"
                        role="tab"
                        aria-controls="all"
                        aria-selected="true"
                      >
                        All
                      </button>
                    </li>
                    {blogCategory.map((blog, id) => {
                      return (
                        <li key={id} className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id={blog.id}
                            data-bs-toggle="tab"
                            data-bs-target={`#${blog.slug}`}
                            type="button"
                            role="tab"
                            aria-controls={blog.slug}
                            aria-selected="false"
                          >
                            {blog.category}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="all"
                      role="tabpanel"
                      aria-labelledby="all-tab"
                    >
                      <div className="content-bottom mt-4">
                        <div className="row row-cols-1 row-cols-md-3 g-4">
                          {blogs.map((blog, id) => {
                            return (
                              <div key={id} className="col tw-mb-6">
                                <Link to={`/blogs/${blog.slug}`}>
                                  <div className="card">
                                    <img
                                      src={
                                        blog.image ? getFileUrl(blog.image) : ""
                                      }
                                      className="card-img-top"
                                      alt="..."
                                    />
                                    <div className="card-body">
                                      {blog.tags !== "undefined" ? (
                                        <div className="tags gap-2 mb-2">
                                          {blog.tags
                                            .split(",")
                                            .map((tag, index) => (
                                              <small
                                                className="card-text"
                                                key={index}
                                              >
                                                {tag}
                                              </small>
                                            ))}
                                        </div>
                                      ) : (
                                        <small
                                          style={{ background: "white" }}
                                        ></small>
                                      )}
                                      <h5 className="card-title">
                                        {blog.title}
                                      </h5>
                                      <p className="card-text">
                                        {removeHTML(blog.description).substring(
                                          0,
                                          200
                                        )}
                                        <strong>...</strong>
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    {blogCategory.map((category, id) => {
                      return (
                        <div
                          className="tab-pane fade"
                          id={category.slug}
                          key={id}
                          role="tabpanel"
                          aria-labelledby={category.id}
                        >
                          <div className="content-bottom m-4">
                            <div className="row row-cols-1 row-cols-md-3 g-4">
                              {blogs
                                .filter((blog) =>
                                  blog.category.includes(category.category)
                                )
                                .map((blog, id) => {
                                  return (
                                    <div key={id} className="col">
                                      <Link to={`/blogs/${blog.slug}`}>
                                        <div className="card">
                                          <img
                                            src={
                                              blog.image
                                                ? getFileUrl(blog.image)
                                                : ""
                                            }
                                            className="card-img-top"
                                            alt="..."
                                          />{" "}
                                          <div className="card-body">
                                            {blog.tags !== "undefined" ? (
                                              <div className="tags gap-2 mb-2">
                                                {blog.tags
                                                  .split(",")
                                                  .map((tag, index) => (
                                                    <small
                                                      className="card-text"
                                                      key={index}
                                                    >
                                                      {tag}
                                                    </small>
                                                  ))}
                                              </div>
                                            ) : (
                                              <small
                                                style={{ background: "white" }}
                                              ></small>
                                            )}
                                            <h5 className="card-title">
                                              {blog.title}
                                            </h5>
                                            <p className="card-text">
                                              {removeHTML(blog.description).substring(
                                                0,
                                                200
                                              )}
                                              <strong>...</strong>
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
    </>
  );
}

export default Blogs;
