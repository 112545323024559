import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

// destinations
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { removeHTML } from "../../utils/util";
import { getDestinations } from "../../services/destinations";
import { getCMSPageTitle } from "../../services/home/home.services";
import getFileUrl from "../../utils/getFileUrl";

const DEFAULT_PAGE_SIZE = 4;
const destination_slug = "destinations-page";

export const Destinations = () => {
  const navigate = useNavigate();

  const navigateToDestinationDetail = (slug) => {
    navigate(`/destinations/${slug}`);
  };

  // Queries
  const { data: destinations, isPending: destinationsLoading } = useQuery({
    queryKey: ["destinations"],
    queryFn: () => getDestinations(10),
  });
  const { data: pageTitle, isPending: pageTitleLoading } = useQuery({
    queryKey: ["pagetitle-cms", destination_slug],
    queryFn: () => getCMSPageTitle(destination_slug, DEFAULT_PAGE_SIZE),
  });

  if (destinationsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{`Epark Consultancy | ${pageTitle?.title}`} </title>
      </Helmet>
      {/* destinations Section */}
      <section className="div-wrapper tw-mb-[7.3rem]">
        <div className="features tw-mt-[1rem] lg:tw-mt-[5.6rem]" id="features">
          <div className="container features-wrapper max-container padding-x">
            <div className="content-header">
              <div className="content d-flex flex-column align-items-center">
                <span className="top-title">All Destinations</span>

                <h3 className="title tw-w-full">
                  {/* {removeHTML(pageTitle?.description)} */}
                  We offer multiple destinations
                </h3>
              </div>
            </div>

            <div className="content-main">
              <div className="tw-flex tw-flex-wrap tw-gap-5 tw-justify-center">
                {destinations?.map((destination) => {
                  const { id,image, title, shortDescription, slug } = destination;
                  return (
                    <div
                      id={id}
                      className="tw-flex tw-flex-col tw-w-full md:tw-w-[42%] lg:tw-w-[27%] tw-cursor-pointer tw-shadow-[0_3px_10px_rgb(0,0,0,0.2)] tw-p-4 hover:tw-scale-105 tw-transition-transform"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigateToDestinationDetail(slug);
                      }}
                    >
                      <div className="tw-flex tw-w-full tw-h-[200px]">
                        <img
                          src={getFileUrl(image)}
                          className="tw-object-cover tw-w-full tw-h-full tw-rounded-md"
                          alt="company"
                        />
                      </div>
                      <h3 className="tw-text-3xl tw-font-semibold tw-mt-5 tw-capitalize">
                        {/* {category} :{item.title} */}
                        {title}
                      </h3>
                      <p className="tw-text-base tw-text-[#757575] tw-pt-3 tw-pb-5">
                        {removeHTML(shortDescription)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
