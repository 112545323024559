import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import makeRequest from "../../utils/api";
import getFileUrl from "../../utils/getFileUrl";
import { removeHTML } from "../../utils/util";
import "./footer.css";
import { ImFacebook } from "react-icons/im";
import { IoLogoInstagram } from "react-icons/io";
import { AiFillLinkedin } from "react-icons/ai";

function Footer() {
  const [siteSettings, setSiteSettings] = useState([]);
  const [newsLetterCMS, setNewsLetterCMS] = useState([]);
  const [footerCMS, setFooterCMS] = useState([]);

  const fetchCMS = async () => {
    const res = await makeRequest(`/cms/signup-to-our-newsletters`);
    if (res) {
      setNewsLetterCMS(res.data?.data);
    }

    const resp = await makeRequest(`/cms/footer`);
    if (resp) {
      setFooterCMS(resp.data?.data);
    }
  };

  const fetchItems = async () => {
    const res = await makeRequest(`/site-settings`);
    if (res) {
      setSiteSettings(res.data?.data);
    }
  };

  useEffect(() => {
    fetchItems();
    fetchCMS();
  }, []);

  return (
    <footer className="footer tw-bg-[#45035a]">
      <div className="container max-container footer-wrapper padding-x">
        <div className="content-main">
          <div className="content-left">
            <Link to="/" className="tw-flex tw-justify-left">
              <img
                src={
                  siteSettings.footerLogo
                    ? getFileUrl(siteSettings.footerLogo)
                    : ""
                }
                alt="footer logo"
              />
            </Link>
            <p className="tw-text-[#FFFFFF] tw-pt-6">
              {removeHTML(newsLetterCMS.description ?? "")}
            </p>
          </div>
          <div className="content-center">
            <h1>GET IN TOUCH</h1>
            <ul className="contact-details">
              <li className="menu-item">{siteSettings.email}</li>
              <li className="menu-item">{siteSettings.phone}</li>
              <li className="menu-item">{siteSettings.address}</li>
            </ul>
            <ul className="social-media">
              <li className="menu-item" style={{ cursor: "pointer" }}>
                <a
                  href={siteSettings.facebook}
                  target="_blank"
                  rel="noreferrer"
                  className="footer_icon tw-flex tw-items-center tw-gap-1"
                >
                  <ImFacebook /> facebook
                </a>
              </li>
              <li className="menu-item" style={{ cursor: "pointer" }}>
                <a
                  href={siteSettings.twitter}
                  target="_blank"
                  rel="noreferrer"
                  className="footer_icon tw-flex tw-items-center tw-gap-1"
                >
                  <IoLogoInstagram /> instagram
                </a>
              </li>
              {/* <li className="menu-item" style={{ cursor: "pointer" }}>
                <a
                  href={siteSettings.linkedIn}
                  target="_blank"
                  rel="noreferrer"
                  className="footer_icon tw-flex tw-items-center tw-gap-1"
                >
                  <AiFillLinkedin /> linkedIn
                </a>
              </li> */}
            </ul>
          </div>
          <div className="content-right">
            <h1>QUICK LINKS</h1>
            <ul className="menu">
              <Link to="/about">
                <li className="menu-item">About EPARK</li>
              </Link>
              <Link to="/blogs">
                <li className="menu-item">Blogs</li>
              </Link>
              <Link to="/careers">
                <li className="menu-item">Career</li>
              </Link>
              <Link to="/privacy-policy">
                <li className="menu-item">Privacy Policy</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="tw-flex tw-justify-center">
          <span className="tw-text-[#D3EAF0]">Copyright 2024 @EPARK CONSULTANCY</span>
        </div>

      </div>
      {/* <div className="content-bottom tw-bg-[#2196B2]">
        <div className="content padding-x">

          <p className="tw-py-6 tw-text-[#D3EAF0]">
            {removeHTML(footerCMS.description ?? "")}
          </p>
          <span className="tw-text-[#D3EAF0]">Copyright 2024 @EPARK CONSULTANCY</span>
        </div>
      </div> */}
    </footer>
  );
}

export default Footer;
