import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineDoubleRight } from "react-icons/ai";

import "./header.css";
import Logo from "./brand-logo.png";

import getFileUrl from "../../utils/getFileUrl";
import makeRequest from "../../utils/api";

import { RxHamburgerMenu } from "react-icons/rx";
// import { GoTriangleDown } from "react-icons/go";

function Header() {
  const [siteSettings, setSiteSettings] = useState([]);

  const fetchCMS = async () => {
    const resp = await makeRequest(`/site-settings`);
    if (resp) {
      setSiteSettings(resp.data?.data);
    }
  };

  useEffect(() => {
    fetchCMS();

    // const handleScroll = () => {
    //   const pageContent = document.getElementById("navigation-wrap");
    //   const scrollTop = pageContent.scrollTop;

    //   // Adjust the header height based on the scroll position of the page content
    //   if (scrollTop > 20) {
    //     setHeaderHeight("50px");
    //   } else {
    //     setHeaderHeight("90px");
    //   }
    // };

    // const pageContent = document.getElementById("navigation-wrap");
    // pageContent.addEventListener("scroll", handleScroll);

    // // Clean up the event listener on component unmount
    // return () => {
    //   pageContent.removeEventListener("scroll", handleScroll);
    // };

    let nav = document.querySelector(".navigation-wrap");

    window.onscroll = function () {
      if (document.documentElement.scrollTop > 40) {
        nav.classList.add("scroll-on");
      } else {
        nav.classList.remove("scroll-on");
      }
    };

    let navBar = document.querySelectorAll(".nav-link");
    let navCollapse = document.querySelector(".navbar-collapse.collapse");
    navBar.forEach(function (a) {
      a.addEventListener("click", function () {
        navCollapse.classList.remove("show");
      });
    });
  }, []);

  return (
    <header>
      <nav
        className="navbar navbar-expand-lg navigation-wrap"
        id="navigation-wrap"
      >
        <div className="container max-container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={
                siteSettings.headerLogo
                  ? getFileUrl(siteSettings.headerLogo)
                  : Logo
              }
              alt=""
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <RxHamburgerMenu className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item ">
                <NavLink className="nav-link" aria-current="page" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link tw-group" to="/services">
                  Services
                  {/* <span className="tw-text-[#787878] group-hover:tw-text-[#4d61ac]">
                    <GoTriangleDown className="tw-w-[14px] tw-h-[14px]" />
                  </span> */}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/destinations">
                  Our Destinations
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/courses">
                  Courses
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/blogs">
                  Blogs
                </NavLink>
              </li>
              <li className="tw-flex tw-justify-center">
                <NavLink to="/contact-us" className="tw-flex tw-items-center tw-w-fit">
                  <button className="tw-shadow-[0_10px_20px_rgba(177,97,_172,_0.5)] btn-contact">Free Consultation  <AiOutlineDoubleRight className="tw-inline-block "/></button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
