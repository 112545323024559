import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import "./destination.css";

// utils
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { removeHTML } from "../../utils/util";
import getFileUrl from "../../utils/getFileUrl";

// destinations
import { getDestination } from "../../services/destinations";

const parseHTML = (htmlString) => {
  return { __html: htmlString };
};

const DestinationDetail = () => {
  const { slug } = useParams();

  const navigate = useNavigate();

  const navigateToContact = () => {
    window.scrollTo(0, 0);
    navigate("/contact-us");
  };

  // Queries
  const { data: destination, isPending: destinationLoading } = useQuery({
    queryKey: ["destination", slug],
    queryFn: () => getDestination(slug),
  });

  if (destinationLoading ) {
    return <LoadingSpinner />;
  }

   return (
    <>
      <Helmet>
        <title>{`Epark Consultancy | ${destination?.title}`} </title>
      </Helmet>
      {/* Hero Section */}
      <section className="div-wrapper">
        <div
          className="tw-flex tw-w-full tw-h-full h-[513px]"
        // style={{
        //   backgroundImage: `url(${getFileUrl(serviceCMS?.[0]?.image)})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        // }}
        >

<div className="max-container hero-div-sm padding-x">
      <p className="top-title-orange">Our Destinations</p>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-10 tw-w-full tw-justify-between tw-pb-24">
        <div className="tw-flex tw-flex-col tw-justify-center lg:tw-w-[50%] tw-w-full">
          <h3 className="title">{destination?.title}</h3>
          <p className="about-desc" dangerouslySetInnerHTML={parseHTML(destination?.description)}>
            {/* {removeHTML(aboutCMS?.[1]?.description)} */}
          </p>
        </div>
        <div className="tw-flex tw-w-full lg:tw-w-[46%] tw-h-[368px] sticky-image">
          <img
            src={getFileUrl(destination?.image)}
            className="tw-object-cover tw-h-full lg:tw-static"
            alt="who we are"
          />
        </div>
      </div>
      <div className="tw-mt-6">
        <button className="btn-orange" onClick={navigateToContact}>
          Quick Enquiry
        </button>
      </div>
    </div>
        </div>
      </section>
    </>
  );
};

export default DestinationDetail;
