import axios from "../utils/axios";

export const getServices = async (take) => {
  const params = new URLSearchParams();
  params.set("take", take);
  params.set("skip", "0");
  params.set("filterBy", "all");

  const response = await axios.get("/services", { params });
  return await response?.data?.data;
};

export const getService = async (id) => {
  const params = new URLSearchParams();
  params.append("id", id);

  const response = await axios.get(`/services/${id}`, { params });
  return await response?.data?.data;
};
