import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import PortfolioIcons from "../../components/portfolio/portfolio-icons";

// import slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Helmet } from "react-helmet";
import { Tab } from "@headlessui/react";

// utils
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { removeHTML } from "../../utils/util";

// services
import {
  getCMS,
  getCMSPageTitle,
  getPortfolio,
} from "../../services/home/home.services";
import getFileUrl from "../../utils/getFileUrl";

const DEFAULT_PAGE_SIZE = 4;
const portfolio_slug = "portfolio-page";

const Portfolio = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const navigate = useNavigate();

  const navigateToContact = () => {
    navigate("/contact-us");
  };

  // Queries
  const { data: portfolioCMS, isPending: portfolioCMSLoading } = useQuery({
    queryKey: ["portfolio-cms", portfolio_slug],
    queryFn: () => getCMS(portfolio_slug, DEFAULT_PAGE_SIZE),
  });
  const { data: pageTitle, isPending: pageTitleLoading } = useQuery({
    queryKey: ["pagetitle-cms", portfolio_slug],
    queryFn: () => getCMSPageTitle(portfolio_slug, DEFAULT_PAGE_SIZE),
  });
  const { data: portfolio, isPending: portfolioLoading } = useQuery({
    queryKey: ["portfolio"],
    queryFn: () => getPortfolio(DEFAULT_PAGE_SIZE),
  });

  if (portfolioCMSLoading || pageTitleLoading || portfolioLoading) {
    return <LoadingSpinner />;
  }

  // Slider Settings
  const settings = {
    dots: true,
    infinite: true,
    adaptiveHeight: false,
    variableWidth: false,
    centerMode: false,
    // autoplay: true,
    // autoplaySpeed: 3500,
    speed: 500,
    slidesToShow: 1,
    rows: 2,
    slidesPerRow: 2,
    initialSlide: 0,
    // slidesToShow: 2,
    // rows: 2,
    // slidesToScroll: 1,
    dotsClass: "button__bar",
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          rows: 3,
          slidesPerRow: 2,
          // slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          rows: 2,
          slidesPerRow: 1,
          // rows: 2,
          // slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          rows: 1,
          slidesPerRow: 1,
          // rows: 2,
          // slidesToShow: 1,
        },
      },
    ],
  };

  // Portfolio Tab Headings
  const portfolioKeys = Object.keys(portfolio);

  return (
    <>
      <Helmet>
        <title>{`Epark Consultancy | ${pageTitle?.title}`}</title>
      </Helmet>

      {/* Portfolio Hero Section */}
      <section className="div-wrapper">
        <div
          className="tw-flex tw-w-ful tw-h-[513px]"
          style={{
            backgroundImage: `url(${getFileUrl(portfolioCMS?.[0]?.image)})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="max-container hero-div padding-x">
            <p className="top-title-orange">Our Works</p>
            <h1 className="title-hero">{portfolioCMS?.[0]?.title}</h1>
            <p className="title-desc-hero">
              {removeHTML(portfolioCMS?.[0]?.description)}
            </p>
            <div className="tw-mt-6">
              <button className="btn-orange" onClick={navigateToContact}>
                Quick Enquiry
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="max-container tw-py-24 padding-x">
        <div className="tw-flex tw-flex-col tw-items-center tw-text-center">
          <p className="top-title tw-mb-2">Our Works</p>
          <h3 className="title">Check Our Portfolio</h3>
        </div>

        <div className="tw-mt-12">
          {/* Portfolio Tabs Section */}
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="tw-flex tw-overflow-x-auto tw-items-start tw-text-[#c1c1c1] md:tw-justify-center">
              <div className="tw-flex md:tw-justify-center tw-gap-5 md:tw-gap-9 tw-md:tw-min-w-2/4 tw-border-b tw-border-[#eaebee]">
                <Tab
                  key={selectedIndex}
                  className={`tw-px-0 ${
                    selectedIndex === 0 ? "tab-active" : "tab-active-hover"
                  }`}
                >
                  All
                </Tab>
                {portfolioKeys.map((item, index) => {
                  return (
                    <Tab
                      key={index}
                      className="tab-active-hover tw-px-0 tw-capitalize"
                    >
                      {item}
                    </Tab>
                  );
                })}
              </div>
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel>
                <Slider {...settings} className="slick-slider tw-mt-12">
                  {portfolioKeys?.flatMap((category) =>
                    portfolio[category].map((item) => {
                      const { id, title, image, description } = item;

                      return (
                        <div className="tw-flex tw-w-full lg:tw-w-[48%]">
                          <div
                            key={id}
                            className="portfolio-slick-slide tw-h-full tw-cursor-pointer tw-flex tw-flex-col"
                            // style={{ height: "620px" }}
                          >
                            <div className="tw-flex tw-w-full tw-h-[332px]">
                              <img
                                src={getFileUrl(image)}
                                className="tw-object-cover tw-w-full tw-h-full tw-rounded-md"
                                alt="company"
                              />
                            </div>
                            <h3 className="tw-text-3xl tw-font-semibold tw-mt-5 tw-capitalize">
                              {/* {category} :{item.title} */}
                              {title}
                            </h3>
                            <p className="tw-text-base tw-text-[#757575] tw-pt-3 tw-pb-5">
                              {removeHTML(description)}
                            </p>

                            {/* social icon buttons */}
                            <PortfolioIcons item={item} />
                          </div>
                        </div>
                      );
                    })
                  )}
                </Slider>
              </Tab.Panel>

              {/* Render Portfolio for Each Category */}
              {portfolioKeys?.map((key, index) => (
                <Tab.Panel key={index}>
                  {portfolioKeys[index] && (
                    <Slider {...settings} className="slick-slider tw-mt-12">
                      {portfolio[portfolioKeys[index]].map(
                        (item, itemIndex) => (
                          <div className="tw-flex tw-w-full lg:tw-w-[48%]">
                            <div
                              className="portfolio-slick-slide tw-h-full tw-cursor-pointer tw-flex tw-flex-col"
                              key={itemIndex}
                            >
                              <div className="tw-flex tw-w-full tw-h-[332px]">
                                <img
                                  src={getFileUrl(item.image[0])}
                                  className="tw-object-cover tw-w-full tw-h-full tw-rounded-md"
                                  alt="company"
                                />
                              </div>
                              <h3 className="tw-text-3xl tw-font-semibold tw-mt-5">
                                {item.title}
                              </h3>
                              <p className="tw-text-base tw-text-[#757575] tw-pt-3 tw-pb-5">
                                {item.description}
                              </p>

                              {/* social icon buttons */}
                              <PortfolioIcons item={item} />
                            </div>
                          </div>
                        )
                      )}
                    </Slider>
                  )}
                </Tab.Panel>
              ))}
              <Tab.Panel></Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
