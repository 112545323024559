import "./training-detail.css";
import { useState, useEffect } from "react";
import makeRequest from "../../utils/api";
import { Link, useParams } from "react-router-dom";
import { ParseHTML } from "../../utils/util";
import TrainingAccordion from "../../components/training-accordion/trainingAccordion";
import ReactQuill from "react-quill";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsBag, BsBookmarkCheck } from "react-icons/bs";
import getFileUrl from "../../utils/getFileUrl";


function TrainingDetail() {
  const [courseDetail, setCourseDetail] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [settings, setSettings] = useState([]);
  const { id } = useParams();

  const fetchSyllabus = async () => {
    const res = await makeRequest(`/syllabus`);
    if (res) {
      setSyllabus(res?.data?.data);
    }
  };

  const fetchSettings = async () => {
    const res = await makeRequest(`/site-settings`);
    if (res) {
      setSettings(res?.data?.data);
    }
  };

  useEffect(() => {
    const fetchDetail = async () => {
      const res = await makeRequest(`/trainings/${id}`);
      if (res) {
        setCourseDetail(res?.data?.data);
      }
    };

    fetchDetail();
    fetchSyllabus();
    fetchSettings();
  }, [id]);

  return (
    <>
      <div className="section one" style={{ paddingTop: "8rem" }}>
        <div className="hero_banner py-lg-5 py-3"
         style={{
          backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0.5)), url(${getFileUrl(courseDetail?.image)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
          <div className="container">
            <div className="row">
              <h1 className="hero-title">{courseDetail?.title}</h1>
              <span className="hero-sub">at Epark Consultancy, {settings.address}</span>
              <p className="hero-des">
                {ParseHTML(courseDetail?.shortDescription)}
              </p>
            </div>
            <div className="row mt-md-5 mt-0">
              {/* <div className=" align-items-center">
                <span>Offered by :</span>{" "}
                <img
                   src={getFileUrl(courseDetail?.image)}
                  style={{ width: 100, objectFit: "contain" }}
                  alt=""
                />
              </div> */}
              <div className="col-8">
                <div className="mt-3">
                  <div
                    className="row mx-auto"
                    style={{
                      marginTop: 6,
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 20,
                      lineHeight: 34,
                    }}
                  >
                    <Link
                      to={`/contact-us/${courseDetail.slug}`}
                      className="btn-pri"
                    >
                      Book Your Seat
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 align-items-center">
                <div className="d-flex gap-2">
                  <div>
                    <AiOutlineClockCircle size={20} />
                  </div>
                  <p className="mb-0">
                    Duration :{" "}
                    {courseDetail.duration ? courseDetail?.duration : "N/A"}
                  </p>
                </div>
                <div className="d-flex gap-2 mt-1">
                  <div>
                    <BsBag size={20} />
                  </div>
                  <p className="mb-0">Category : {courseDetail?.category}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-des">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="crd">
                <div className="crd-title">
                  <h2 className="crd-h2">About Course</h2>
                </div>
                <div className="crd-des">
                  {ParseHTML(courseDetail?.longDescription)}
                </div>
              </div>
              <div className="crd crd-blue">
                <div className="crd-title">
                  <h2 className="crd-h2">What You Will Learn?</h2>
                </div>
                <div className="crd-des">
                  <ReactQuill
                    value={courseDetail?.courseObjective}
                    readOnly={true}
                    theme={"bubble"}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 pt-lg-0 pt-5">
              <div className="crd-feature">
                <img
                  src="../assets/images/calendar.png"
                  className="fea-icon"
                  alt=""
                />
                <div className="fea-title">
                  <h4>Flexible timing</h4>
                </div>
                <div className="fea-des">
                  We start new session every monday.
                </div>
              </div>
              <div className="crd-feature">
                <img
                  src="../assets/images/teacher.png"
                  className="fea-icon"
                  alt=""
                />
                <div className="fea-title">
                  <h4>Experienced  Teachers</h4>
                </div>
                <div className="fea-des">
                  classes are taken by experienced teachers
                </div>
              </div>
              <div className="crd-feature">
                <img
                  src="../assets/images/test.png"
                  className="fea-icon"
                  alt=""
                />
                <div className="fea-title">
                  <h4>Free mock tests</h4>
                </div>
                <div className="fea-des">
                 We provide 3 free mock tests.
                </div>
              </div>
              <div className="crd-feature">
                <img
                  src="../assets/images/stack-of-books.png"
                  className="fea-icon"
                  alt=""
                />
                <div className="fea-title">
                  Study materials
                </div>
                <div className="fea-des">
                 You will get study materials for free.
                </div>
              </div>
              <div className="crd-feature">
                <img
                  src="../assets/images/clock.png"
                  className="fea-icon"
                  alt=""
                />
                <div className="fea-title">
                  <h4>
                    {courseDetail.duration ? courseDetail.duration : "N/A"}
                  </h4>
                </div>
                <div className="fea-des">
                 Course will end in {courseDetail.duration ? courseDetail.duration : "N/A"}.
                </div>
              </div>
              <div className="crd-feature">
              <img
                  src="../assets/images/chair.png"
                  className="fea-icon"
                  alt=""
                />
                {/* <BsBookmarkCheck className="fea-icon" color="#4d61ac" /> */}
                <div className="fea-title">
                  <h4>Book your Seat</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-des">
        <div className="container">
          <div className="row">
            <h2 className="top-title mb-2" style={{ textAlign: "center" }}>Syllabus</h2>
          </div>
          <div className="row">
            <div className="accordion">
              {syllabus
                .filter((item) => item.course === courseDetail.title)
                .map((item, index) => (
                  <div key={index}>
                    <TrainingAccordion key={index} item={item} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrainingDetail;
