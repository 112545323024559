import { Route, Routes } from "react-router-dom";
import Home from "./Home/home";
import Training from "./Training/training";
import Blogs from "./Blogs/blogs";
import BlogDetail from "./Blog-Detail/blog-detail";
import About from "./About/about";
import Careers from "./Career/Careers";
import PageNotFound from "./404Page";
import Contact from "./Contact/contact";
import TrainingDetail from "./Training/training-detail";
import PrivacyPolicy from "./privacy-policy";
import ServiceDetail from "./Services/ServiceDetail";
import DestinationDetail from "./Destinations/DestinationDetails";
import Portfolio from "./Portfolio/Portfolio";
import CareerDetail from "./Career/CareerDetail";
import { Services } from "./Services/Services";
import { Destinations } from "./Destinations/Destinations";

function PageRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/trainings" element={<Training></Training>} />
        <Route path="/course/:id" element={<TrainingDetail />} />
        <Route path="/blogs" element={<Blogs></Blogs>} />
        <Route path="/blogs/:id" element={<BlogDetail></BlogDetail>} />
        <Route path="/about" element={<About></About>} />
        <Route path="/careers" element={<Careers></Careers>} />
        <Route
          path="/career-detail/:slug"
          element={<CareerDetail></CareerDetail>}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<Contact></Contact>} />
        <Route path="/contact-us/:id" element={<Contact></Contact>} />
        <Route path="/services" element={<Services></Services>} />
        <Route path="/destinations" element={<Destinations></Destinations>} />
        <Route path="/courses" element={<Training></Training>} />
        <Route
          path="/services/:slug"
          element={<ServiceDetail></ServiceDetail>}
        />
          <Route
          path="/destinations/:slug"
          element={<DestinationDetail></DestinationDetail>}
        />
        <Route path="/portfolio" element={<Portfolio></Portfolio>} />
        <Route path="*" element={<PageNotFound></PageNotFound>} />
      </Routes>
    </>
  );
}

export default PageRoutes;
